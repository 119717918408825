import { is_elementor_edit } from 'js/utils';

export default function sticky_footer_cta_init(){

    let container = document.querySelector('.sticky_footer_cta');
    if( !container ) return;

    if( is_elementor_edit() ) {
        import('./sticky_footer_cta.scss');
        return;
    }
    
    if( getCookie('footer_sticky_cta_close') ) {
        container.remove();
        return;
    }

    import('./sticky_footer_cta.scss');
    import('./sticky_footer_cta');
}