import { on_ready, is_elementor_edit } from 'js/utils';
import { retina_init } from 'js/functions';
import { toggle_height_init } from 'js/toggle_height_init';
import header_sticky_init from 'modules/header/header_sticky_init';
import forms_init from 'modules/forms/forms';
import carousels_init from 'modules/carousel/carousel';
import footer_init from 'modules/footer/footer';
import nav_menu_init from 'modules/menu/nav/nav';

import { swiper_mobile_init } from 'js/functions';

// load global styles
import './main.scss';

on_ready(()=>{

    on_elementor_edit_screen();
    
    if( is_elementor_edit() ) {
        return;
    }

    const tasks = [
        header_sticky_init,
        nav_menu_init,
        forms_init, // forms ui + gravityforms customizations
        carousels_init, // swiper
        retina_init, // load @2x images
        toggle_height_init, // toggle element height from 0 to default - for animating height
        on_mobile,
        swiper_mobile_init,
    ];
    tasks.forEach(task=>setTimeout(task, 0));

    dynamic_load_init();
});

function on_elementor_edit_screen(){
    footer_init();
}

function on_mobile(){
    if( window.innerWidth > 768 ) return;
    close_footer_dropdowns();
}

function close_footer_dropdowns(){
    document.querySelectorAll('.elementor-location-footer .ff_toggle_height_trigger.open').forEach(dropdown=>{
        dropdown.click();
    })
}

function dynamic_load_init(){

    // search popup: remove if not used
    if( document.querySelector('.search_popup_open') ) {
        import('modules/search/search_popup/init');
    }
    
    if( document.querySelector('.slide_menu_open') ) {
        import('modules/menu/slide_menu/init');
    }

    // wishlist & compare: remove if not used
    if( document.querySelector('.compare_count') ||
        document.querySelector('.wishlist_count') ) {
        import('modules/save_items/init');
    }

    // cookie consent
    // if( !getCookie('ff_cookie_consent') ) {
    //     import('modules/cookie_consent/simple/simple')
    // }
}

// Show/hide Section
document.addEventListener('DOMContentLoaded', function () {
    const sections = document.querySelectorAll('.show-hide');

    sections.forEach(section => {
        const button = section.querySelector('.expand-btn');
        if (!button) return;
        const formContainer = section.querySelector('.hidden-box');
        if (!formContainer) return;
        const buttonText = button.querySelector('.elementor-button-text');
        if (!buttonText) return;
        const initialText = buttonText.textContent;

        button.addEventListener('click', function (e) {
            e.preventDefault();
            button.classList.toggle('active');
            formContainer.classList.toggle('active');
            // Optional: Toggle the button text
            // buttonText.textContent = button.classList.contains('active') ? 'Close' : initialText;
        });
    });
});