import Toggle_Height from 'classes/Toggle_Height';

export function toggle_height_init(){

    document.querySelectorAll('.ff_toggle_height').forEach(container=>{
        
        if( typeof container.ff_toggle_height !== 'undefined' ) return;

        const trigger = container.querySelector('.ff_toggle_height_trigger');
        const target = container.querySelector('.ff_toggle_height_target');
        if( !trigger || !target ) return;
        
        container.ff_toggle_height = new Toggle_Height({
            trigger,
            target,
        });
    })
    
}