const activation_point = 700;

export default function header_sticky_init(){
    document.addEventListener('scroll', on_scroll, {passive: true});
}

function on_scroll(){
    if( window.scrollY < activation_point ) return;
    document.removeEventListener('scroll', on_scroll);
    import('./header_sticky.scss');
    import('./header_sticky');
}