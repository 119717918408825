export default function nav_menu_init(){

    const hover_class = 'open_sub';

    document.querySelectorAll('.main_menu .menu-item-has-children').forEach(li=>{

        const a = li.children[0];
        let timeout = null;

        a.addEventListener('pointerenter', () => {
            clearTimeout(timeout);
            li.classList.add(hover_class);
        });

        a.addEventListener('pointerleave', () => {
        	clearTimeout(timeout);
        	timeout = setTimeout(() => {
        		li.classList.remove(hover_class);
        	}, 400);
        });

        a.addEventListener('click', e=>{
            e.preventDefault();
        })
    })
    
}