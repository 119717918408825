import sticky_footer_cta_init from './sticky_footer_cta/init';

export default function footer_init() {
    sticky_footer_cta_init();
    scroll_to_top_btn_init();
}

function scroll_to_top_btn_init(){
    const btn = document.querySelector('.scroll_to_top_btn');
    if( !btn ) return;
    btn.addEventListener('click', ()=>{
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    })
}